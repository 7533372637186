<template>
  <div class="wrapper">
    <div class="base-container" v-show="isShowList">
      <div class="header-mol">
        <div class="info">
          <h3 class="title">文章管理</h3>
          <p class="summary">
            添加公众号的文章，公众号必须要关联当前小程序，否则小程序端无法查看详情页；<a
              class="blue"
              target="__black"
              href="https://developers.weixin.qq.com/community/develop/doc/000242cccdc670f02ea977e105e409?highLine=%25E5%2585%25AC%25E4%25BC%2597%25E5%258F%25B7%25E5%25A6%2582%25E4%25BD%2595%25E5%2585%25B3%25E8%2581%2594%25E5%25B0%258F%25E7%25A8%258B%25E5%25BA%258F"
              >公众号如何关联小程序？</a
            >
          </p>
        </div>
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="isShowList = false"
          >发布文章</el-button
        >
      </div>
      <div class="filter">
        <el-input
          placeholder="文章标题"
          v-model="queryData.title"
          style="width: 320px;"
          class="f-search-bar"
        >
          <el-button slot="append" @click="search">搜索</el-button>
        </el-input>
        <el-select
          class="select"
          v-model="queryData.status"
          placeholder="状态"
          clearable
          @change="search"
        >
          <el-option
            v-for="(item, key) in status"
            :key="key"
            :label="item"
            :value="key"
          ></el-option>
        </el-select>
        <el-select
          class="select"
          v-model="queryData.articleContentType"
          placeholder="类型"
          clearable
          @change="search"
        >
          <el-option value="1" label="图文"></el-option>
          <el-option value="2" label="公众号"></el-option>
        </el-select>
        <el-autocomplete
          v-if="!isEstateArticle"
          class="select"
          prefix-icon="el-icon-search"
          value-key="authName"
          v-model="queryData.authUserName"
          :fetch-suggestions="querySearchAsync"
          :hide-loading="hideFetchLoading"
          placeholder="关联认证用户"
          @select="handleUserSelect"
        >
          <div style="line-height: 34px;padding: 0;" slot-scope="{ item }">
            {{ item.authName }}
          </div>
        </el-autocomplete>
      </div>
      <el-table border class="body" style="margin-top: 10px;" :data="list">
        <el-table-column
          label="文章ID"
          prop="articleId"
          width="100px"
        ></el-table-column>
        <el-table-column label="文章" width="500px">
          <template slot-scope="scope">
            <div class="base-info">
              <el-image
                class="cover"
                fit="cover"
                :src="scope.row.coverImagePath"
              ></el-image>
              <div class="text">
                <p class="name">
                  {{ scope.row.title
                  }}<span class="f-top-badge" v-if="scope.row.top">荐</span>
                </p>
                <div class="sub x5" v-if="scope.row.relationAuthorName">
                  <el-image
                    class="avatar"
                    :src="scope.row.relationAuthorAvatar"
                  ></el-image>
                  <p>{{ scope.row.relationAuthorName }}</p>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <p :class="{ red: scope.row.status === 0 }">
              {{ status[scope.row.status] }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="浏览量" prop="applyUserName">
          <template slot-scope="scope">
            <!-- {{ scope.row.viewNum || "-" }} / {{ scope.row.shareNum || "-" }} -->
            <el-dropdown
              :ref="'viewCountDropdown' + scope.$index"
              trigger="click"
              @visible-change="() => (curViewCount = scope.row.viewNum)"
            >
              <el-button type="text" class="mw10-i">
                {{ scope.row.viewNum }}
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <div class="f-top-dropdown">
                  <div class="title">编辑浏览量<span class="red">*</span></div>
                  <el-input
                    v-model.number="curViewCount"
                    maxlength="7"
                    placeholder="值越小越靠前"
                  ></el-input>
                  <div class="foot">
                    <el-button
                      size="mini"
                      @click="closeViewCountDropdown(scope.$index)"
                      >取消</el-button
                    >
                    <el-button
                      type="primary"
                      size="mini"
                      @click="editViewCount(scope.row.articleId, scope.$index)"
                      >确定</el-button
                    >
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <el-table-column label="创建人" prop="createUserName"></el-table-column>
        <el-table-column
          label="创建时间"
          prop="inDate"
          width="200px"
        ></el-table-column>
        <el-table-column label="操作" width="230px">
          <template slot-scope="scope">
            <el-button type="text" @click="showEdit(scope.row.articleId)"
              >编辑</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.status != 1"
              @click="changeArticleStatus(scope.row.articleId, 1)"
              >发布</el-button
            >
            <el-button
              type="text"
              v-if="scope.row.status == 1"
              @click="changeArticleStatus(scope.row.articleId, 0)"
              >下架</el-button
            >
            <el-button
              v-if="!isEstateArticle"
              type="text"
              @click="changeArticleTop(scope.row.articleId, !scope.row.top)"
              >{{ scope.row.top ? "取荐" : "推荐" }}</el-button
            >
            <el-dropdown
              v-if="!isEstateArticle"
              @visible-change="
                bool =>
                  getQrCode(
                    scope.row.articleId,
                    bool,
                    scope.row.articleContentType
                  )
              "
            >
              <el-button type="text" class="ml10">预览</el-button>
              <el-dropdown-menu slot="dropdown" disabled>
                <el-dropdown-item class="dropdown-qrcode">
                  <el-image class="img" fit="cover" :src="curViewQrCode">
                    <i
                      v-if="!curViewQrCode"
                      slot="error"
                      class="qrcode-loading el-icon-loading"
                    ></i>
                  </el-image>
                  <p class="tips">
                    请使用微信扫描二维码
                  </p>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-popconfirm
              v-if="scope.row.status != 1"
              title="确定删除此篇文章吗？"
              @onConfirm="delArticle(scope.row.articleId)"
            >
              <el-button slot="reference" class="ml10" type="text"
                >删除</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="pageSizeChange"
        @current-change="pageNumChange"
        :current-page.sync="queryData.pageNum"
        :page-sizes="pageSizes"
        :page-size="queryData.pageSize"
        layout="sizes, prev, pager, next,jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <add-article
      :class="{ 'add-article-hide': isShowList }"
      @complete="getArticleList"
      @close="closeAdd"
      :aritcleId="curAritcleId"
    ></add-article>
  </div>
</template>

<script>
import {
  getPlatformArticleManageList,
  getEstateArticleManageList,
  getAuthUserList,
  deleteArticle,
  changeArticleStatus,
  changeArticleTop,
  editArticleViewCount
} from "@/api/appManage";
import { getPlatformQrCode } from "@/api/platform";
import AddArticle from "../_components/addArticle";
export default {
  components: {
    AddArticle
  },
  data() {
    return {
      list: [],
      total: 0,
      status: {
        0: "草稿",
        1: "已发布"
      },
      pageSizes: [5, 10, 20, 30, 50],
      queryData: {
        pageNum: 1,
        pageSize: 5,
        articleContentType: "", //类型:1.图文,2.公众号 ,
        authUserId: null,
        authUserName: "",
        status: "", //状态-0:草稿,1:发布,2:下架 ,
        title: ""
      },
      curViewCount: 0, //当前编辑浏览量
      isEstateArticle: false, //是楼盘文章
      curAritcleId: null,
      hideFetchLoading: false,
      authUserList: [],
      isShowList: true,
      cachedQrCode: {}, //缓存二维码
      curViewQrCode: "" //当前显示二维码图片
    };
  },
  created() {
    this.getArticleList();
    if (this.$route.path == "/estate/dictionary/article") {
      this.isEstateArticle = true;
    }
  },
  methods: {
    closeAdd() {
      this.isShowList = true;
      this.curAritcleId = `refresh${Date.now()}`;
    },
    showEdit(id) {
      this.curAritcleId = id;
      this.isShowList = false;
    },
    async editViewCount(articleId, index) {
      //编辑浏览量
      const res = await editArticleViewCount({
        articleId,
        viewCount: this.curViewCount
      });
      if (res) {
        this.$showSuccess("编辑成功");
        this.closeViewCountDropdown(index);
        this.getArticleList();
      }
    },
    closeViewCountDropdown(index) {
      //关闭浏览量编辑下拉框
      this.$refs[`viewCountDropdown${index}`].hide();
    },
    async getQrCode(id, show, type) {
      //获取二维码
      if (!show) return;
      if (this.cachedQrCode[id]) {
        this.curViewQrCode = this.cachedQrCode[id];
        return;
      }
      this.curViewQrCode = "";
      const res = await getPlatformQrCode({
        scene: `articleDetail#${id}#${type}`
      });
      if (res) {
        this.curViewQrCode = res;
        this.cachedQrCode[id] = res;
      }
    },
    async changeArticleTop(id, status) {
      //推荐取荐
      const res = await changeArticleTop({ id, status });
      if (res) {
        this.getArticleList();
      }
    },
    async changeArticleStatus(id, status) {
      //更改文章状态
      const res = await changeArticleStatus({ id, status });
      if (res) {
        this.getArticleList();
      }
    },
    async delArticle(id) {
      //删除文章
      const res = await deleteArticle({ id });
      if (res) {
        this.$showSuccess("删除成功");
        this.getArticleList();
      }
    },
    async search() {
      this.queryData.pageNum = 1;
      this.getArticleList();
    },
    async searchAuthUser(authName) {
      //搜索用户
      const res = await getAuthUserList({ authName });
      if (res) {
        this.authUserList = res;
      }
    },
    async querySearchAsync(authName, cb) {
      //查询用户列表
      this.hideFetchLoading = true;
      if (!authName || !authName.trim()) {
        this.handleUserSelect({});
        return;
      }
      this.hideFetchLoading = false;
      let data = await getAuthUserList({ authName });
      data = data || [];
      this.hideFetchLoading = true;
      cb(data);
    },
    handleUserSelect(item) {
      this.queryData.authUserId = item.id;
      this.search();
    },
    pageSizeChange(num) {
      this.queryData.pageSize = num;
      this.getArticleList();
    },
    pageNumChange(num) {
      this.queryData.pageNum = num;
      this.getArticleList();
    },
    async getArticleList() {
      //获取列表
      const fn =
        this.$route.path == "/estate/dictionary/article"
          ? getEstateArticleManageList
          : getPlatformArticleManageList;
      const res = await fn(this.queryData);
      if (res) {
        this.list = res.list;
        this.total = res.total;
      }
    }
  }
};
</script>
<style lang="scss">
.dropdown-qrcode {
  padding: 30px 30px 20px 30px;
  .tips {
    line-height: 20px;
    text-align: center;
    color: $color9;
  }
  .img {
    width: 200px;
    height: 200px;
  }
}
</style>

<style scoped lang="scss">
.add-article-hide {
  position: fixed;
  left: -200%;
  opacity: 0;
  pointer-events: none;
}
.ml10 {
  margin-left: 10px;
}
.qrcode-loading {
  font-size: 48px;
  color: #ccc;
  position: absolute;
  left: 37%;
  top: 60px;
}
.back-btn {
  position: fixed;
  left: 360px;
  top: 12px;
  z-index: 900;
}
.wrapper {
  height: 100%;
  position: relative;
}
.base-info {
  display: flex;
  .cover {
    width: 130px;
    min-width: 130px;
    height: 100px;
    border-radius: 5px;
  }
  .text {
    height: 100px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .sub {
      color: $color9;
      .avatar {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }
}
.filter {
  padding-top: 20px;
  .select {
    width: 150px;
    margin-left: 10px;
  }
}
</style>
